import axios from 'axios';
import { errorHandling } from '@/service/helpers.js';
import store from '@/store/index.js';

class DepotsService {
  async getDepots(config) {
    let url = 'panel/api/v1/depots?paginate=1';

    if (config != undefined) {
      let params = new URLSearchParams(config).toString();
      url = `${url}&${params}`;
    }
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }

  async getDepotDetails(id) {
    let url = `panel/api/v1/depots/${id}`;
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }

  async updateDepot(id, form) {
    let url = `panel/api/v1/depots/${id}`;
    try {
      const response = await form.put(url);
      store.commit('setAppNotifications', {
        title: 'Magazyn zaktualizowany',
        content: 'Pomyslnie zaktualizowano magazyn',
        type: 'success',
        autoclose: true,
      });
      return response;
    } catch (error) {
      store.commit('setAppNotifications', {
        title: 'Bląd podczas aktualizacji',
        content: 'Blad podczas aktualziacji',
        type: 'error',
        autoclose: true,
      });
      return errorHandling(error);
    }
  }

  async addDepot(form) {
    let url = `panel/api/v1/depots`;
    try {
      const response = await form.post(url);
      store.commit('setAppNotifications', {
        title: 'Magazyn dodany',
        content: 'Pomyślnie dodano  magazyn',
        type: 'success',
        autoclose: true,
      });
      return response;
    } catch (error) {
      store.commit('setAppNotifications', {
        title: 'Bląd podczas dodawania',
        content: 'Blad podczas dodawania',
        type: 'error',
        autoclose: true,
      });
      return errorHandling(error);
    }
  }

  async deleteDepot(id) {
    let url = `panel/api/v1/depots/${id}`;
    try {
      const response = await axios.delete(url);
      return response;
    } catch (error) {
      return errorHandling(error);
    }
  }
}

export default new DepotsService();
