<template>
  <nav
    class="flex mx-auto mt-6 mb-2 px-4 sm:px-6 md:px-8"
    aria-label="Breadcrumb"
  >
    <ol class="flex items-center space-x-2">
      <li>
        <div>
          <router-link
            :to="{ name: 'Main' }"
            class="text-gray-400 hover:text-gray-500"
          >
            <DynamicIconSolid
              icon="HomeIcon"
              class="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
            />
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>

      <li v-for="page in paths" :key="page.name">
        <div class="flex items-center">
          <DynamicIconSolid
            icon="ChevronRightIcon"
            class="flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />

          <router-link
            :to="{ name: page.name }"
            class="ml-2"
            v-slot="{ isExactActive }"
          >
            <span
              class="text-sm font-normla"
              :class="[
                isExactActive
                  ? 'text-gray-300 pointer-events-none'
                  : 'text-gray-500 hover:text-gray-700',
              ]"
            >
              <!-- {{ page.name }}  -->
              {{ $t(`menu.${page.name.toLowerCase()}`) }}
            </span>
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    paths: Array,
  },
};
</script>
