{
  "common": {
    "search": "Szukaj",
    "password": "Hasło",
    "email_address": "Email",
    "remember_me": "Zapamiętaj mnnie",
    "forgot_password": "Zapmniałeś hasła?",
    "sign_in": "Zaloguj",
    "logout": "Wyloguj",
    "true": "tak",
    "false": "nie",
    "new": "Nowy",
    "add": "Dodaj",
    "update": "Aktalizuj"
  },
  "menu": {
    "dashboard": "Panel Zarządzania",
    "customers": "Klienci",
    "subscribers": "Subskrybenci",
    "loyalty_program": "Program Lojalnościowy",
    "catalog": "Katalog",
    "products": "Produkty",
    "categories": "Kategorie",
    "attributes": "Atrybuty",
    "sales": "Sprzedaż",
    "employees": "Pracownicy",
    "newsletters": "Newslettery",
    "newsletter_interests": "Zainteresowania",
    "configuration": "Konfiguracja",
    "tax_groups": "Grupy podatkowe",
    "orders": "Zamówienia",
    "marketing": "Marketing",
    "promotions": "Promocje",
    "resources": "Zasoby",
    "depots": "Magazyny",
    "containers": "Kontenery",
    "payments": "Płatności"
  },
  "views": {
    "dashboard": {
      "title": "Panel zarządzania"
    },
    "customers": {
      "customers": {
        "title": "Klienci"
      },
      "customersDetails":{
        "title": "Szczegóły"
      },
      "subscribers": {
        "title": "Subskrybenci"
      }
    },
    "employees": {
      "employees": {
        "title": "Pracownicy"
      },
      "employeesDetails":{
        "title": "Szczegóły"
      }
    },
    "catalog": {
      "attributes": {
        "title": "Atrybuty"
      },
      "categories": {
        "title": "Kategorie"
      },
      "products": {
        "title": "Produkty"
      }
    },
    "newsletters": {
      "newsletters": {
        "title": "Newslettery"
      }
    },
    "newsletter_interests": {
      "newsletter_interests": {
        "title": "Newslettery - zainteresowania"
      }
    },
    "sales": {
      "orders": {
        "title": "Zamówienia"
      },
      "orderDetails": {
        "title": "Szczegóły zamówienia"
      },
      "payments": {
        "title": "Płatności",
        "subtitle": "Zarzadzanie płatnościami"
      }
    },
    "configuration": {
      "tax_groups": {
        "title": "Grupy podatkowe"
      }
    },
    "marketing": {
      "promotions": {
        "title": "Promocje"
      }
    },
    "resources": {
      "depots": {
        "title": "Magazyny",
        "subtitle": "Zarządzaj zasobami magazynów"
      },
      "containers": {
        "title": "Kontenery",
        "subtitle": "Zarządzaj zasobami kontenerów"
      }
    }
  }
}
