{
  "common": {
    "search": "Search",
    "password": "Password",
    "email_address": "Email address",
    "remember_me": "Remember me",
    "forgot_password": "Forgot your password?",
    "sign_in": "Sign in",
    "logout": "Logout",
    "true": "yes",
    "false": "no",
    "new": "New",
    "add": "Add",
    "update": "Update"
  },
  "menu": {
    "dashboard": "Dashboard",
    "customers": "Customers",
    "subscribers": "Subscribers",
    "loyalty_program": "Loyalty Program",
    "catalog": "Catalog",
    "products": "Products",
    "categories": "Categories",
    "attributes": "Attributes",
    "sales": "Sales",
    "employees": "Employees",
    "newsletters": "Newsletters",
    "newsletter_interests": "Interests",
    "configuration": "Configuration",
    "tax_groups": "Tax groups",
    "orders": "Orders",
    "marketing": "Marketing",
    "promotions": "Promotions",
    "resources": "Resources",
    "depots": "Depots",
    "payments": "Payments"
  },
  "views": {
    "dashboard": {
      "title": "Dashboard"
    },
    "customers": {
      "customers": {
        "title": "Customers"
      },
      "customersDetails":{
        "title": "Details"
      },
      "subscribers": {
        "title": "Subscribers"
      }
    },
    "employees": {
      "employees": {
        "title": "Employees"
      },
      "employeesDetails":{
        "title": "Details"
      }
    },
    "catalog": {
      "attributes": {
        "title": "Attributes"
      },
      "categories": {
        "title": "Categories"
      },
      "products": {
        "title": "Products"
      }
    },
    "newsletters": {
      "newsletters": {
        "title": "Newsletters"
      }
    },
    "newsletter_interests": {
      "newsletter_interests": {
        "title": "Interests"
      }
    },
    "sales": {
      "orders": {
        "title": "Orders"
      },
      "orderDetails": {
        "title": "Orders details"
      },
      "payments": {
        "title": "Payments",
        "subtitle": "Payment management"
      }
    },
    "configuration": {
      "tax_groups": {
        "title": "Tax groups"
      }
    },
    "marketing": {
      "promotions": {
        "title": "Promotions"
      }
    },
    "resources": {
      "depots": {
        "title": "Depots",
        "subtitle": "Manage warehouse resources"
      }
    }
  }
}
